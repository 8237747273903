toastr.options = {
    "closeButton": true,
    "debug": false,
    "positionClass": "toast-top-right",
    "onclick": null,
    "showDuration": "1000",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

if (document.querySelector('#token')) {
    Vue.http.headers.common['X-CSRF-TOKEN'] = document.querySelector('#token').getAttribute('value');
}

var elements = $('.datetimepicker');
elements.attr('type', 'text');
elements.datetimepicker({
    language: 'de',
    sideBySide: true,
    minDate: moment(),
    format: 'dd.mm.yyyy hh:ii',
    autoclose:true,
    calendarWeeks:true,
    immediateUpdates:true,
    todayHighlight:true
});

var elements = $('.datepicker');
elements.attr('type', 'text');
elements.datepicker({
    language: 'de',
    endDate: '-18y',
    format: 'dd.mm.yyyy',
    autoclose:true,
    calendarWeeks:true,
    immediateUpdates: true
});
