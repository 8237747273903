if (document.querySelector('#dashboard')) {
    var vm = new Vue({
        el:'#dashboard',
        data: {
            //User search
            search:'',
            found_users:[],
            position:-1,
            //Active Items
            customer: {
                active:null,//selected customer
                name:'',
                email:'',
                telephone:'',
                setting:false,//create form show on/off
                project_ids_only:[],
                errors: {
                    name:null,
                    email:null,
                    telephone:null
                }
            },
            project: {
                active:null,//selected project
                title:'',
                url:'',
                customer_id:null,
                setting:false,//create form show on/off
                sweet_select:false,
                env:'',
                errors: {
                    title:null,
                    url:null,
                    env:null
                }
            },
            user: {
                active:null,//selected user (unused at the time)
                first_name:'',
                last_name:'',
                gender:'none',
                email:'',
                password:'',
                password_confirmation:'',
                level:0,
                customer_id:null,
                project_ids_only:[],
                setting:false,//create form show on/off
                sweet_select:false,
                errors: {
                    first_name:null,
                    last_name:null,
                    gender:null,
                    email:null,
                    password:null,
                    password_confirmation:null,
                    projects:null
                }
            },
            //Listing of the resources
            customers: [],
            projects: [],
            users: [],
            sweets: []
        },
        methods: {
            /**
             * User search handling
             */
            matchingIndex:function(index) {
                return this.position==index;
            },
            moveActiveIndexUp:function() {
                if (this.found_users.length > 0) {
                    if (this.found_users.length > 0 && this.position < this.found_users.length -1) {
                        this.position++;
                    } else {
                        this.position = -1;
                    }
                }
            },
            moveActiveIndexDown:function() {
                if (this.found_users.length > 0) {
                    if (this.position > -1) {
                        this.position--;
                    } else {
                        this.position = this.found_users.length - 1;
                    }
                }
            },
            addUserToProject:function() {
                if (this.position > -1) {
                    console.log("Projekt hinzufügen:");
                    var user    = this.found_users[this.$get('position')];
                    var project = this.project.active;

                    this.userToProject(
                        user,
                        project
                    );
                }
            },
            userToProject:function(user, project_id) {
                /**
                 * attach the new project.
                 */
                if (user.project_ids_only.indexOf(project_id) == -1) {
                    user.project_ids_only.push(project_id);
                }

                this.$resource('user{/id}').update(
                    {
                        id: user.id
                    },
                    user
                ).then(function (response) {
                    toastr.success(user.first_name + " " + user.last_name, "Benutzer erfolgreich dem Projekt zugewiesen!");
                    this.setUsers(project_id);
                }, function (response) {
                    toastr.error(user.first_name + " " + user.last_name, "Beim zuweisen des Nutzers zum Projekt ist etwas schief gegangen!");
                });
            },
            /**
             * Customer Handling.
             */
            clearCustomerCreate:function() {
                this.customer.name      = '';
                this.customer.email     = '';
                this.customer.telephone = '';
                this.customer.errors    = {
                    name:null,
                    email:null,
                    telephone:null
                };
            },
            updateCustomer:function(index) {
                var myself      = this;

                this.$resource('customer{/id}').update(
                    {
                        id: this.customers[index].id
                    },
                    this.customers[index]
                ).then(function (response) {
                    //myself.customer[index] = response.data;
                    toastr.success(myself.customers[index].name, "Änderung erfolgreich gespeichert!");
                }, function (response) {
                    toastr.error(myself.customers[index].name, "Änderung fehlgeschlagen!");

                    for (var property in response.data) {
                        if (response.data.hasOwnProperty(property)) {
                            myself.customers[index].errors[property] = true;
                        }
                    }
                });
            },
            saveCustomer:function() {
                var myself      = this;

                this.$resource('customer').save(
                    null,
                    this.customer
                ).then(function (response) {
                    myself.$set('customers[' + myself.customers.length + ']', response.data);
                    toastr.success(myself.customer.name, "Kunde erfolgreich gespeichert!");
                    this.toggleCustomerCreate();
                    this.clearCustomerCreate();
                }, function (response) {
                    for (var property in response.data) {
                        if (response.data.hasOwnProperty(property)) {
                            myself.customer.errors[property] = true;
                        }
                    }

                    toastr.error(
                        myself.customer.name,
                        "Kunde speichern fehlgeschlagen!"
                    );
                });
            },
            deleteCustomer:function(index) {
                var myself      = this;

                this.$resource('customer{/id}').remove(
                    {
                        id: this.customers[index].id
                    },
                    null
                ).then(function (response) {
                    toastr.success(myself.customers[index].name, "Kunde erfolgreich gelöscht!");
                    myself.customers.$remove(myself.customers[index]);
                }, function (response) {
                    toastr.error(myself.customers[index].name, "Kunde konnte nicht gelöscht werden");
                });
            },
            toggleCustomerUpdate:function(index) {
                this.$set('customers[' + index + '].setting', !this.customers[index].setting);
            },

            toggleCustomerCreate:function() {
                this.$set('customer.setting', !this.customer.setting);
            },

            /**
             * Project Handling.
             */
            clearProjectCreate:function() {
                this.project.title = '';
                this.project.url   = '';
                this.project.env   = '';
                this.project.errors   = {
                    title:null,
                    url:null,
                    env:null
                };
            },
            updateProject:function(index) {
                var myself      = this;

                this.$resource('project{/id}').update(
                    {
                        id: this.projects[index].id
                    },
                    this.projects[index]
                ).then(function (response) {
                    //myself.project[index] = response.data;
                    toastr.success(myself.projects[index].title, "Änderung erfolgreich gespeichert!");
                }, function (response) {
                    toastr.error(myself.projects[index].title, "Änderung fehlgeschlagen!");

                    for (var property in response.data) {
                        if (response.data.hasOwnProperty(property)) {
                            myself.projects[index].errors[property] = true;
                        }
                    }
                });
            },
            saveProject:function() {
                var myself      = this;

                this.$resource('project').save(
                    null,
                    this.project
                ).then(function (response) {
                    myself.$set('projects[' + myself.projects.length + ']', response.data);
                    toastr.success(myself.project.title, "Projekt erfolgreich gespeichert!");
                    this.toggleProjectCreate();
                    this.clearProjectCreate();
                }, function (response) {
                    for (var property in response.data) {
                        if (response.data.hasOwnProperty(property)) {
                            myself.project.errors[property] = true;
                        }
                    }

                    toastr.error(
                        myself.project.title,
                        "Projekt speichern fehlgeschlagen!"
                    );
                });
            },
            deleteProject:function(index) {
                var myself      = this;

                this.$resource('project{/id}').remove(
                    {
                        id: this.projects[index].id
                    },
                    null
                ).then(function (response) {
                    toastr.success(myself.projects[index].title, "Projekt erfolgreich gelöscht!");
                    myself.projects.$remove(myself.projects[index]);
                }, function (response) {
                    toastr.error(myself.projects[index].title, "Projekt konnte nicht gelöscht werden");
                });
            },
            toggleProjectUpdate:function(index) {
                this.$set('projects[' + index + '].setting', !this.projects[index].setting);
                this.$set('projects[' + index + '].sweet_select', false);
            },

            toggleProjectCreate:function() {
                this.$set('project.setting', !this.project.setting);
            },
            toggleProjectSweets:function(index) {
                this.$set('projects[' + index + '].sweet_select', !this.projects[index].sweet_select);
                this.$set('projects[' + index + '].setting', false);

                if (this.projects[index].sweet_select) {
                    this.setSweets();
                }
            },

            /**
             * User Handling.
             */
            clearUserCreate:function() {
                this.user.title = '';
                this.user.url   = '';
                this.user.first_name    = '';
                this.user.last_name     = '';
                this.user.gender    = 'none';
                this.user.email     = '';
                this.user.password  = '';
                this.user.password_confirmation = '';
                this.user.level    = 0;
                this.user.errors   = {
                    first_name:null,
                    last_name:null,
                    gender:null,
                    email:null,
                    password:null,
                    password_confirmation:null
                };
            },
            updateUser:function(index) {
                var myself      = this;

                /**
                 * Set active project, needed to associate project specific attributes.
                 * @type {null}
                 */
                this.users[index].active_project = this.project.active;

                this.$resource('user{/id}').update(
                    {
                        id: this.users[index].id
                    },
                    this.users[index]
                ).then(function (response) {
                    //myself.user[index] = response.data;
                    toastr.success(myself.users[index].title, "Änderung erfolgreich gespeichert!");
                }, function (response) {
                    toastr.error(myself.users[index].title, "Änderung fehlgeschlagen!");

                    for (var property in response.data) {
                        if (response.data.hasOwnProperty(property)) {
                            myself.users[index].errors[property] = true;
                        }
                    }
                });
            },
            saveUser:function() {
                var myself      = this;

                this.$resource('user').save(
                    null,
                    this.user
                ).then(function (response) {
                    /**
                     * Set user in users list only if project_id matches saved user project_ids!
                     */
                    if (myself.user.project_ids_only.indexOf(myself.project.active) != -1) {
                        myself.$set('users[' + myself.users.length + ']', response.data);
                    }

                    toastr.success(myself.user.title, "Benutzer erfolgreich gespeichert!");
                    this.toggleUserCreate();
                    this.clearUserCreate();
                }, function (response) {
                    for (var property in response.data) {
                        if (response.data.hasOwnProperty(property)) {
                            myself.user.errors[property] = true;
                        }
                    }

                    toastr.error(
                        myself.user.title,
                        "Benutzer speichern fehlgeschlagen!"
                    );
                });
            },
            deleteUser:function(index) {
                var myself      = this;

                this.$resource('user{/id}').remove(
                    {
                        id: this.users[index].id
                    },
                    null
                ).then(function (response) {
                    toastr.success(myself.users[index].title, "Benutzer erfolgreich gelöscht!");
                    myself.users.$remove(myself.users[index]);
                }, function (response) {
                    toastr.error(myself.users[index].title, "Benutzer konnte nicht gelöscht werden");
                });
            },
            toggleUserUpdate:function(index) {
                console.log(index);
                this.$set('users[' + index + '].setting', !this.users[index].setting);
                this.$set('users[' + index + '].sweet_select', false);
            },

            toggleUserCreate:function() {
                this.$set('user.setting', !this.user.setting);
            },
            toggleUserSweets:function(index) {
                this.$set('users[' + index + '].sweet_select', !this.users[index].sweet_select);
                this.$set('users[' + index + '].setting', false);

                if (this.users[index].sweet_select) {
                    this.setSweets();
                }
            },

            /**
             * General handling and loading of Data.
             * @param customer
             */
            setCustomer:function(customer) {
                this.customer.active    = customer;
                this.project.active     = null;
                this.project.customer_id= customer;
                this.user.customer_id   = customer;
            },
            setProject:function(project) {
                this.project.active     = project;
                this.user.active        = null;
            },
            setCustomers:function(){
                this.$resource('customer').get().then(function (response) {
                    this.$set('customers', response.data);
                });
            },
            setProjects:function(customer){
                this.$resource('customer{/id}').get({id:this.customer.active}).then(function (response) {
                    this.$set('projects', response.data.projects);

                    /**
                     * set available project ids for actual selected customer.
                     * @type {Array|*}
                     */
                    this.$set('customer.project_ids_only', response.data.project_ids_only);
                });
            },
            setUsers:function(project){
                this.$resource('project{/id}').get({id:this.project.active}).then(function (response) {
                    this.$set('users', response.data.users);
                });
            },
            setSweets:function(){
                this.$resource('sweet').get().then(function (response) {
                    this.$set('sweets', response.data);
                });
            },
            findByKey:function(list, key, value) {//helper for finding an entry with a special key value pair.
                for (var i in list) {
                    if (list[i].hasOwnProperty(key) && list[i][key] == value) {
                        return list[i];
                    }
                }
                return null;
            }
        },
        computed: {
            activeCustomer:function() {
                var customer = this.findByKey(this.customers, 'id', this.customer.active);

                return customer ? customer : this.customer;
            },
            activeProject:function() {
                var project = this.findByKey(this.projects, 'id', this.project.active);

                return project ? project : this.project;
            }
        },
        ready: function() {
            this.setCustomers();
        }
    });

    vm.$watch('customer.active', function (newVal, oldVal) {
        this.setProjects(newVal);
    });

    vm.$watch('project.active', function (newVal, oldVal) {
        this.setUsers(newVal);
    });

    vm.$watch('search', function (newVal, oldVal) {
        var myself = this;
        if ($(".Dashboard__search").is(':focus')) {
            $.ajax({
                url:'/user/search',
                data:{
                    query:myself.search
                },
                success:function(result) {
                    myself.found_users = result;
                }
            });
        }
    });

    /**
     * just to bridge the delay.
     */
    $('.Dashboard__search-instant-results').removeClass('hidden');

    /**
     * small helper to clean after focus is lost.
     */
    $(".Dashboard__search").focusout(function() {
        vm.found_users = [];
    });
}